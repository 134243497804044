.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #151515;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  color: #fff;
}

.logo {
  margin-bottom: 2rem;
}

.logo img {
  max-width: 200px;
  height: auto;
}

.text {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.letters {
  display: inline-block;
  margin: 0 0.5rem;
}

.progress {
  height: 100%;
  background-color: #333;
  transition: width 0.5s ease;
  position: absolute;
  left: 0;
  top: 0;
}

.count {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  margin: 0;
  z-index: 1;
}
