@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");

html {
  @apply scroll-smooth;
}

body {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.text-body-text {
  @apply text-stone-900 dark:text-stone-100;
}
.font-body {
  font-family: Manrope, system-ui, -apple-system, BlinkMacSystemFont;
}
.bg-body {
}
.text-bright {
  color: var(--text-color-bright);
}
.bg-light {
  background-color: var(--background-light);
}
.border-body-text {
  border-color: var(--text-color);
}

/* @layer base {

 *,
 *:after,
 *:before {
     @apply box-border p-0 m-0;
 }
 h1 {
     @apply mb-4 text-3xl font-extrabold md:text-4xl xl:text-5xl text-stone-900 dark:text-white;
 }
 h2 {
     @apply text-3xl;
 }
 button {
 
 }
 a {
     @apply text-[#61dafb]
 }

 a:focus {
     @apply no-underline outline-primary;
 }
 p,
 table {
     @apply mb-6 text-lg text-justify text-stone-900 dark:text-gray-300 font-body md:text-neutral;
 }
 main {
     @apply mb-8;
 }
 b, strong {
     @apply font-bold text-stone-700 dark:text-neutral-300;
 }
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
     @apply mb-4 font-extrabold text-stone-900 dark:text-white font-body;
 }
 figcaption {
     @apply block mt-2 text-sm italic text-center;
 }
 hr {
     @apply mb-4 border-x-0 border-t-0 border border-b border-[#505050];
 }
 img {
     @apply w-full max-w-full;
 }
 a > img,
 a:hover > img {
     @apply no-underline;
 }
 a {
     @apply text-[#61dafb]  ;
 }
 a:hover,
 a:visited:hover {
     @apply text-[#407584];
 }
 a:focus {
     @apply outline-primary no-underline decoration-primary  text-[#6cdaff];
 }
 html {
     @apply text-[16px] text-body-text font-body;
 }
 ul,
 ol {
     @apply text-stone-900 dark:text-white font-body;
 }
 body {
     @apply bg-stone-200 dark:bg-stone-950 text-bright;
 }
 td,
 th {
     @apply p-2 border border-white;
 }
 blockquote {
     @apply relative italic p-4 mb-6 before:content-[""] before:h-full before:w-1 before:inline-block before:absolute before:top-0 before:left-0 before:right-0 before:bg-[#61dafb] bg-[#1d4b5830]; 
 }
 blockquote p {
     @apply mb-0;
 }
 table {
     @apply block w-full overflow-x-scroll border border-collapse border-body-text;
 }
 th {
     @apply text-white;
 }
 em {
     @apply flex flex-wrap overflow-x-hidden
 }
 i {
     @apply text-stone-800 dark:text-stone-300 font-semibold
 }
 
 .page-intro {
     @apply w-full max-w-lg mx-auto mt-0 mb-8 text-center;
 }

}

@layer components {
     .time {
         @apply block text-xs text-center text-bright;
     }
     .content {
         @apply block;
     }
     .content ul {
         @apply list-disc;
     }
     .content ol {
         @apply list-decimal;
     }
     .content pre code {
         @apply overflow-hidden text-xs md:text-neutral;
     }
     .content li {
         @apply mb-2;
     }
     .content ul ul,
     .content ol ol,
     .content ol ul,
     .content ul ol {
         @apply mb-0 list-inside;
         list-style-type: square;
     }
     .content img {
         @apply block object-cover mb-4;
     }
     .content ul,
     .content ol {
         @apply px-4;
     }
     .content li p {
         @apply mb-0;
     }
     .content time {
         @apply block mb-8 text-center;
     }
} */
